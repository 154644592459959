import React, { useState } from "react";

import wordpress from "../../images/round-icon-wordpress.png";
import download from "../../images/round-icon-download.png";
import importIcon from "../../images/round-icon-import.png"
import customize from "../../images/round-icon-customize.png";
import Rebranding from "./Rebranding";
import youtubeimg from '../../images/youtubeimg.jpg';


const UpgradesContent = () => {

    const [playing, setPlaying] = useState(false);
    const [frameUrl, setFrameUrl] = useState();

    const addFrameUrl = () => {
        setPlaying(true);
        setFrameUrl('https://player.vimeo.com/video/514577280?');
    }

    const closePop = () => {
        setPlaying(false);
        setFrameUrl('');
    }

    return (
        <>
            <div className="col-md-8">
                <div className="tab-content mt-3" id="v-pills-tabContent">
                    <div className="tab-pane fade show active">
                        <h5 className="text-white">Welcome</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-10 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>Welcome!</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                To access your upgrades, click any of the available options on the left side menu.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-fbtemplate" role="tabpanel" aria-labelledby="v-pills-fbtemplate-tab">
                        <h5 className="text-white">FB Templates</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>Facebook Ads Templates</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download this done-for-you Facebook and Instagram ad creatives
                                                and ad copy templates that can easily be customized to be used
                                                for any niche
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  mt-5">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content text-center p-3   text-white  ">
                                        <div className="upgrade-heading"></div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download your Facebook Ads Templates here
                                            </p>
                                        </div>
                                        <a
                                            href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download the Templates</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-vidoeflix"
                        role="tabpanel"
                        aria-labelledby="v-pills-vidoeflix-tab"
                    >
                        <h5 className="text-white">Video Flix</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>VideoFLIX - Netflix Style Video Site Builder</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                VideoFLIX is a subscription-based video content management app
                                                built on cloud. Using VideoFLIX you will be able to create
                                                your own video content sharing website similar to NetFlix,
                                                HBO-Go, DisneyPLUS etc; sharing your own or others unique
                                                video content as channels or series. You will be able to
                                                create free, one-time or monthly payment plans for your
                                                customers to access your video content and build your video
                                                content subscription business!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix_2.2.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download VideoFlix</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix+Training+Docs+updated.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download Tutorial Docs</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-udamy"
                        role="tabpanel"
                        aria-labelledby="v-pills-udamy-tab"
                    >
                        <h5 className="text-white">Acadeable</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>Acadeable</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download Udemy Style Website to showcase your courses. Instant
                                                Access to Udemy Style Website Builder to Sell Courses
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download Setup</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center mt-5">
                                <div className="col-md-12 mt-2">
                                    <h5 className="text-white">Tutorials</h5>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/How+to+install+%26+setup+Acadeable.mp4"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">
                                                    How to install & setup Acadeable
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installation+with+Apache.pdf"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">
                                                    Installation with Apache
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installing+Acedeable.pdf"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">
                                                    Installing Acedeable
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/PWA+Setup.pdf"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">
                                                    PWA Setup
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Server+Requirements.pdf"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">
                                                    Server Requirements
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-2">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Shared+Hosting+Install.pdf"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">
                                                    Shared Hosting Install
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-dfycourse"
                        role="tabpanel"
                        aria-labelledby="v-pills-dfycourse-tab"
                    >
                        <h5 className="text-white">DFY Course</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6 className="m-0">DFY Courses</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 1</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 11</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 2</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 12</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 3</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 13</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 4</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 14</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 5</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 15</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 6</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 16</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                            target="_blank"
                                        >
                                            {" "}
                                            <button className="btn-change7">Download DFY Course 7</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip"
                                            target="_blank"
                                        >
                                            {" "}
                                            <button className="btn-change7">Download DFY Course 17</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                            target="_blank"
                                        >
                                            {" "}
                                            <button className="btn-change7">Download DFY Course 8</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 18</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 9</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 19</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 10</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center p-3 text-white">
                                        <a
                                            href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip"
                                            target="_blank"
                                        >
                                            <button className="btn-change7">Download DFY Course 20</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-client"
                        role="tabpanel"
                        aria-labelledby="v-pills-client-tab"
                    >
                        <h5 className="text-white">Client Contract</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>Client Contract</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download our "Client Contract" template. This is a docx file,
                                                works best with MS Word. Edit the doc and add your business
                                                name, address and other details. Fill the empty blank space
                                                with your service, eg Video Marketing, Local Reputation
                                                Management, Social Media Marketing, Design &amp; Creatives,
                                                Ads Management and so on.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row   mt-5">
                                <div className="col-md-4">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-heading">
                                            <h6>Brief Pitch Template1_2</h6>
                                        </div>
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-heading">
                                            <h6>Service Agreement</h6>
                                        </div>
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-heading">
                                            <h6>Meeting Setup Template1_2</h6>
                                        </div>
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-reseller"
                        role="tabpanel"
                        aria-labelledby="v-pills-reseller-tab"
                    >
                        <h5 className="text-white">Reseller License</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>Reseller License</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Go to JVZoo Dashboard and request your affiliate link. Once
                                                you've requested, submit a ticket with your ClipsReel Business
                                                transaction ID, affiliate ID and email address for our team to
                                                activate your reseller license. Please allow upto 2 working
                                                days.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://www.jvzoo.com/affiliate/affiliateinfo/index/372667"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">
                                                    Request Affiliate Link
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a href="https://support.vineasx.com/" target="_blank">
                                                <button className="btn-change7">Raise Support Ticket</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-webhosting"
                        role="tabpanel"
                        aria-labelledby="v-pills-webhosting-tab"
                    >
                        <h5 className="text-white">Web Hosting</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>Web Hosting</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Please raise a support ticket with your ClipsReel purchase
                                                transaction ID, your domain name that you currently own and
                                                wish to use with the hosting, email address, your full name
                                                and a note about how you would be using the webhosting for
                                                your business.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a href="https://support.vineasx.com/" target="_blank">
                                                <button className="btn-change7">Raise a Ticket</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-videocommercial"
                        role="tabpanel"
                        aria-labelledby="v-pills-videocommercial-tab"
                    >
                        <h5 className="text-white">Video Commercial</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>Video Commercial</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                6X Stunning &amp; Compelling Video Commercials
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/6X+Stunning+%26+Compelling+Video+Commercials+.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Download Videos</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-videopromo"
                        role="tabpanel"
                        aria-labelledby="v-pills-videopromo-tab"
                    >
                        <h5 className="text-white">Video Promos</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-9 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>Video Promos</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Open a ticket with the following details per video:
                                            </p>
                                            <ol>
                                                <li>Your ClipsReel credentials</li>
                                                <li>Permission to access your account</li>
                                                <li>
                                                    What is the purpose of this video that you wish to create?
                                                </li>
                                                <li>Name of the template you wish to use</li>
                                                <li>Number of the template you wish to use</li>
                                                <li>Screenshot of the template you wish to use</li>
                                                <li>
                                                    Content for the video. Please click on the EDIT option for
                                                    the template you wish to use and share with us all the text
                                                    content, logo, music file or Text-to-speech content along
                                                    with images/video clips you wish to use in your videos.
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a href="https://support.vineasx.com/" target="_blank">
                                                {" "}
                                                <button className="btn-change7">Raise a Ticket</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading text-center">
                                            <h6>Note</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                we have the rights to deny a request if it does not match with
                                                the video template requirements. Video creation can take up to
                                                5-6 working days. The video will be available inside your
                                                ClipsReel Account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-dfylead"
                        role="tabpanel"
                        aria-labelledby="v-pills-dfylead-tab"
                    >
                        <h5 className="text-white">DFY Lead Magnets</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-10 mx-auto">
                                    <div className="upgrade-box-content">
                                        <div className="upgrade-heading">
                                            <h6 className="text-white">DFY Lead Magnets</h6>
                                        </div>
                                        <div className="row d-flex mt-5 mb-5 justify-content-center">
                                            <div className="col-md-5">
                                                <div className="upgrade-para">
                                                    <a
                                                        href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
                                                        target="_blank"
                                                    >
                                                        <button className="btn-change7" type="button">
                                                            Download
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-agency"
                        role="tabpanel"
                        aria-labelledby="v-pills-agency-tab"
                    >
                        <h5 className="text-white">Agency</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-12 mx-auto">
                                    <div className="upgrade-box-content p-2 text-white  ">
                                        <div className="row">
                                            <div className="col-md-2 pr-0 text-center ">
                                                <img src={wordpress} alt="wprdpress" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="upgrade">
                                                    <p className="m-0">
                                                        Fresh Install and configure wordpress at your server. If
                                                        you need help in installing and configuring wordpress,
                                                        Click here to find the tutorial.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                        <div className="row">
                                            <div className="col-md-2 pr-0 text-center ">
                                                <img src={download} alt="download" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="upgrade">
                                                    <p className="m-0">
                                                        Once wordpress is installed at your server. Download the
                                                        site content by clicking the "Download site button". This
                                                        will download a wpress file which you will be needing to
                                                        import in your wordpress site.
                                                    </p>
                                                    <div className="row  d-flex justify-content-center">
                                                        <div className="col-md-10">
                                                            <div className="upgrade-box-content text-center  text-white">
                                                                <div className="upgrade-para p-3">
                                                                    <a
                                                                        href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="btn-change7">
                                                                            Download the site
                                                                        </button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                        <div className="row">
                                            <div className="col-md-2 pr-0 text-center ">
                                                <img src={importIcon} alt="import" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="upgrade">
                                                    <p className="m-0">
                                                        Import the xml file in to your wordpress site. If you need
                                                        help importing the xml file in wordpress, Click here to
                                                        find the tutorial.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                        <div className="row">
                                            <div className="col-md-2 pr-0 text-center ">
                                                <img src={customize} alt="customize" />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="upgrade">
                                                    <p className="m-0">
                                                        Customize the site as according to your need. If you need
                                                        help customizing the site. Click here to find the tutorial
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 className="mt-3 text-white">Tutorial</h5>
                        <div className="upgrade-content mt-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="upload-box-content">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="tutorial-content">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <img src={wordpress} alt="wordpress" />
                                                        </div>
                                                        <div className="col-md-10 d-flex align-items-center">
                                                            <div className="upgrade ml-lg-4">
                                                                <a
                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                                                    target="_blank"
                                                                >
                                                                    <button
                                                                        title="Install WordPress From cPanel"
                                                                        className="btn-change7"
                                                                    >
                                                                        Install WordPress From cPanel
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <img src={importIcon} />
                                                        </div>
                                                        <div className="col-md-10 d-flex align-items-center ">
                                                            <div className="upgrade ml-lg-4">
                                                                <a
                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                                                    target="_blank"
                                                                >
                                                                    <button className="btn-change7">
                                                                        Improt SyVid ClipsReel
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <img src={customize} />
                                                        </div>
                                                        <div className="col-md-10 d-flex align-items-center ">
                                                            <div className="upgrade ml-lg-4">
                                                                <a
                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                                                    target="_blank"
                                                                >
                                                                    <button className="btn-change7">
                                                                        Customize website
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <img src={download} />
                                                        </div>
                                                        <div className="col-md-10 d-flex align-items-center ">
                                                            <div className="upgrade ml-lg-4">
                                                                <a
                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                                                    target="_blank"
                                                                >
                                                                    <button
                                                                        className="btn-change7"
                                                                        title="Upload Video and Usage in ClipsReel Website"
                                                                    >
                                                                        Upload Video and Usage in ClipsReel Website
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card fb-card border-0">
                                                    <div
                                                        className="card-header text-center"
                                                        style={{ background: "rgb(0, 123, 255)" }}
                                                    >
                                                        <h6 className="text-white Account-Management-headings">
                                                            How to Install the Agency Website
                                                        </h6>
                                                    </div>
                                                    <div className="card-body p-0">
                                                        <img src={youtubeimg} alt="" className="img-fluid" />
                                                        <span className="youtubeicon" onClick={(e) => addFrameUrl()} style={{ cursor: "pointer", color: '#007bff' }} data-toggle="modal" data-target="#MyModal" ><i class="fas fa-play-circle"></i></span>
                                                        {/* <div className="vid-wrapper">
                                                        <div className="plyr__video-embed" id="player">
                                                            <iframe
                                                                src="https://player.vimeo.com/video/514577280?"
                                                                frameBorder={0}
                                                                allow="autoplay"
                                                                allowFullScreen
                                                                width="100%"
                                                                height="100%"
                                                            />
                                                        </div>
                                                    </div> */}
                                                    </div>
                                                    <div >

                                                    </div>
                                                </div>
                                                <div className={`modal ${playing ? 'show-modal' : ''} `}>
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"></h5>
                                                                <button type="button" onClick={(e) => closePop('')} className="close" data-dismiss="modal"
                                                                    aria-label="Close">
                                                                    <span aria-hidden="true">
                                                                        <i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="embed-responsive embed-responsive-16by9">
                                                                    <iframe id="frameSrc" className="embed-responsive-item" src={frameUrl} allowFullScreen=""></iframe>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-social"
                        role="tabpanel"
                        aria-labelledby="v-pills-social-tab"
                    >
                        <h5 className="text-white">Social Media Assets</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>Social Media Assets</h6>
                                        </div>
                                        <div className="upgrade-para">
                                            <p className="text-center ">
                                                Download your plug &amp; play social media assets below
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/24+Month+Social+Media+Calendar.xlsx"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Social Media Calendar</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/800+Motivational+Videos.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Social Media Captions</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/6900%2B+Facts+Images.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">
                                                    Social Media Video Posts
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">
                                                    Inspirational, Motivational Quotes Images
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">
                                                    Social Media Image Posts
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">
                                                    Library of Quotes for social Media{" "}
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-dfyarticles"
                        role="tabpanel"
                        aria-labelledby="v-pills-dfyarticles-tab"
                    >
                        <h5 className="text-white">DFY Articles</h5>
                        <div className="upgrade-content">
                            <div className="row">
                                <div className="col-md-8 mx-auto">
                                    <div className="upgrade-box-content  text-white  ">
                                        <div className="upgrade-heading">
                                            <h6>DFY Articles</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack1.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Artical Pack 1</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack6.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Artical Pack 6</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Artical Pack 2</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack7.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Artical Pack 7</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Artical Pack 3</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Artical Pack 8 </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack4.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Artical Pack 4</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button className="btn-change7">Artical Pack 8 </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  d-flex justify-content-center  mt-5">
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack5.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Artical Pack 5</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack10.zip"
                                                target="_blank"
                                            >
                                                <button className="btn-change7">Artical Pack 10 </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-wlrebranding"
                        role="tabpanel"
                        aria-labelledby="v-pills-wlrebranding-tab"
                    >
                        <h5 className="text-white">Rebranding</h5>
                        <Rebranding />
                    </div>
                    <div
                        className="tab-pane fade"
                        id="v-pills-business"
                        role="tabpanel"
                        aria-labelledby="v-pills-business-tab"
                    >
                        <h5 className="text-white">Business Finder</h5>
                        <iframe
                            src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q"
                            style={{ width: "100%", height: "100vh" }}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default UpgradesContent;