import React from "react";
import {Helmet} from "react-helmet";
import PrivacyEmail from "./PrivacyEmail";
import PrivacyGdpr from "./PrivacyGdpr";
import Navbar from "../Navbar";
import Footer from "../Footer";
import {useSelector} from "react-redux";

const Privacy = () => {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'ClipsReel'} | Privacy</title>
            </Helmet>
            <Navbar/>

            <section className="gdpr-sec">
                <div className="container">
                    <PrivacyEmail/>
                    <PrivacyGdpr/>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Privacy;