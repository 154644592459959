import React from "react";
import EditTeamClient from "./EditTeamClient";

const ManageTeam = ({users,fetchClientTeam}) => {
    return (
        <>
            <div className="row mt-5">
                <div className="col-md-12 text-white mt-5">
                    <h5>Manage Team Member Account</h5>
                    <div className="team-member-box mt-3">
                        <div className="row">
                            <div className="col-md-12" style={{overflow:"auto",scrollbarWidth:"thin"}}>
                                <table className="table table-striped table-dark my-table-dark">
                                    <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email Address</th>
                                        <th scope="col">Account Type</th>
                                        <th scope="col">Created</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        users.length > 0 ?
                                        users.map((user,index)=>{
                                            return(
                                                <>
                                                    <EditTeamClient
                                                        type="Team Member"
                                                        user={user}
                                                        index={index}
                                                        fetchClientTeam={fetchClientTeam}
                                                        key={`team_${index}`}
                                                    />
                                                </>
                                            )
                                        })
                                        : ''
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
)
}

export default ManageTeam;