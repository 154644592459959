import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useSelector } from "react-redux";
import AudioFiles from "./AudioFiles";
import InfiniteScroll from "react-infinite-scroll-component";

const MediaAudio = ({ currentUrl, setCurrentUrl, selectedAudio, setSelectedAudio }) => {

    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [audioData, setAudioData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [error, setError] = useState(false);

    const fetchAudio = (page = 1) => {
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}search-media`,
            data: JSON.stringify({ search_type: 'music', page: page, 'keyword': '' }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setAudioData(audioData.concat(res.data.data));
                setPageCount(page);
            } else {
                setError(res.data.message);
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    useEffect(() => {
        fetchAudio();

        return () => {
            setAudioData([]);
        }
    }, [])

    return (
        <>
            <div className="row m-0" id="scrollableAudio" style={{ overflowX: "hidden" }}>
                <div className="alert alert-primary" role="alert">
                    This music will apply to all slides
                </div>
                <InfiniteScroll
                    dataLength={audioData.length} //This is important field to render the next data
                    next={() => fetchAudio(pageCount + 1)}
                    hasMore={true}
                    scrollableTarget="scrollableAudio"
                >
                    {
                        audioData.length > 0 ?
                            audioData.map((audio, index) => {
                                return (
                                    <AudioFiles
                                        title={audio.title}
                                        url={audio.url}
                                        currentUrl={currentUrl}
                                        setCurrentUrl={setCurrentUrl}
                                        selectedAudio={selectedAudio}
                                        setSelectedAudio={setSelectedAudio}
                                        key={index} />
                                )
                            })
                            : ''
                    }
                    {
                        loader ?
                            <div className="col-md-12">
                                <h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                            </div>
                            : ''
                    }
                    {
                        error ?
                            <div className="col-md-12">
                                <h6 className="text-center">{error}</h6>
                            </div>
                            : ''
                    }
                </InfiniteScroll>
            </div>
        </>
    )
}

export default MediaAudio;