import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import "../../components/editor/css/editor.css"
import "../../components/editor/css/responsive.css"
import ReactPlayer from "react-player";
import clip from "../../images/clip.mp4";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import limg from "../../images/L NEXT.png";
import pathimg from "../../images/Path 51.png";
import pauseImg from "../../images/pause_icon.png";
import rimg from "../../images/R NEXT.png";
import axios from "axios";
import { appName, baseURL } from "../../global/global";
import { useHistory } from "react-router-dom";
import loaderImg from "../../images/imgloader.gif";
import { setAlert } from "../../actions/alert";

const ReelMarge = () => {

    const history = useHistory();

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const videoData = useSelector(state => state.campaigns.data);
    const brandName = useSelector(state => state.rebrand.data);

    const [mediaReady, setMediaReady] = useState(false);
    const [loader, setLoader] = useState(false);
    const [play, setPlay] = useState(false);
    const [controlsWidth, setControlsWidth] = useState(100);
    const [ratio, setRatio] = useState('16:9');
    const [videos, setVideos] = useState([]);
    const [mergeVideos, setMergeVideos] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(false);
    const [mergeData, setMergeData] = useState({
        data: [],
        name: '',
        preview_image: false,
        ratio: '16:9',
        user_id: auth.user.id
    })

    let dimArr = ratio.split(':');

    const handleRatio = (e) => {
        setMergeData({ ...mergeData, ratio: e.target.value });
        setMergeVideos([]);
        setRatio(e.target.value);
    }

    const handleMerge = (item) => {

        let jsonObj = {
            id: item.id,
            name: item.name,
            preview_image: item.poster,
            url: item.url
        }

        setCurrentSlide(jsonObj);
        setMergeVideos([...mergeVideos, jsonObj]);
    }

    const deleteSlide = (index) => {
        let removeArr = [...mergeVideos];
        removeArr.splice(index, 1);

        setMergeVideos(removeArr);
    }


    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            mergeVideos,
            result.source.index,
            result.destination.index
        );

        setMergeVideos(items);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const activeSlide = (video) => {
        setCurrentSlide(video)
    }

    async function videoPlay(video, index) {
        return new Promise((resolve, reject) => {
            if (index === 0) {
                setTimeout(() => {
                    resolve(video);
                }, 0)
            } else {
                setTimeout(() => {
                    resolve(video);
                }, 8000)
            }

        });
    }

    const handlePlay = async () => {
        console.log('click function');
        setPlay(true);

        for (let i = 0; i < mergeVideos.length; i++) {
            await videoPlay(mergeVideos[i], i).then(r => {
                setCurrentSlide(r)
            }).catch(err => {
                console.log(err);
                setCurrentSlide(mergeVideos[i])
            })

        }

        setPlay(false);

    }

    const handlePause = () => {
        setPlay(false);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        mergeData.data = mergeVideos;
        mergeData.preview_image = mergeVideos[0].preview_image;

        axios({
            method: 'POST',
            url: `${baseURL}create-merge`,
            data: mergeData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            console.log(res);
            if (res.data.status === true) {
                history.push('/projects');
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }

            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })

        console.log(mergeData);
    }

    useEffect(() => {
        if (videoData.length > 0) {
            let filterVideo = videoData.filter((video) => video.status === "6" && video.size === mergeData.ratio);
            setVideos(filterVideo);
        }
    }, [ratio])

    useEffect(() => {
        let preWidth = document.querySelector('.preview-wrapper');
        let parentWidth = preWidth.offsetWidth;

        let wrapWidth = document.querySelector('.aspect-ratio-wrapper');
        let demoWidth = (wrapWidth.offsetWidth / parentWidth * 100).toFixed();

        setControlsWidth(demoWidth);
    }, [])


    useEffect(() => {
        if (auth.user.membership !== '') {

            let memberships = auth.user.membership.split('__');
            if (!memberships.includes('unlimited') && !memberships.includes('unlimitedlite')) {
                history.push('/dashboard')
            }

        } else {
            history.push('/dashboard')
        }
    }, [])


    return (
        <>
            <main className="editor-main">
                <Helmet>
                    <title>{brandName !== false ? brandName.name : appName} | Reel Merge </title>
                </Helmet>
                <Navbar />

                <section className="editor-sec">
                    <div className="row editor-container m-0">
                        <div className="colmn colmn-1 order-lg-1 order-2 p-0">
                            <div className="editor-part">
                                <div className="row p-2 " style={{ marginTop: "20%", marginBottom: "20%" }}>
                                    {/*<ul className="d-flex flex-wrap list-inline m-0 align-items-center">*/}
                                    {
                                        videos.length > 0 ?
                                            videos.map((item, index) => {
                                                return (
                                                    <div className="col-6 cursor-pointer mb-4 text-center merge-wrap-img" key={item.id} onClick={() => handleMerge(item)}>
                                                        <img src={item.poster} alt="" className="img-fluid" />
                                                    </div>
                                                )
                                            })
                                            : <h5 className="text-center text-white">No Videos Created</h5>
                                    }
                                    {/*</ul>*/}
                                </div>
                            </div>
                        </div>

                        <div className="colmn colmn-2 order-lg-2 order-1 p-0">
                            <div className="colmn-preview-wrapper">
                                <div className="save-render-sec reel-select" style={{ marginTop: "65px" }}>
                                    <div className="row m-0">
                                        <form method="post" onSubmit={(e) => handleFormSubmit(e)} className="col-md-12 d-flex flex-wrap align-items-center justify-content-center">
                                            <div className="add-campaign mr-4 ">
                                                <select defaultValue={`16:9`} name="type" className="custom-form" onChange={(e) => handleRatio(e)}>
                                                    <option value="16:9">Regular</option>
                                                    <option value="1:1">Square</option>
                                                    <option value="9:16">Story</option>
                                                </select>
                                            </div>
                                            <div className="add-campaign mr-4 ">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Add Campaign Name"
                                                    defaultValue={mergeData.name}
                                                    required
                                                    onChange={(e) => setMergeData({ ...mergeData, name: e.target.value })}
                                                />
                                            </div>
                                            <div className="save-projectbtn mr-4 ">
                                                <button type="submit" className="btn-change6" disabled={mergeVideos.length > 1 ? false : true} >
                                                    <span className="mr-1">
                                                        {loader ? <i className="fa fa-spinner fa-spin" /> : <i className="fas fa-file-video" />}
                                                    </span>
                                                    Merge
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                {/* Preview part */}
                                <div className={`editor-preview mx-auto  ${ratio === '16:9' ? 'ep-as-r-16-9' : ratio === '9:16' ? 'ep-as-r-9-16' : ratio === '1:1' ? 'ep-as-r-1-1' : ''}`}>
                                    <div className="editor-preview-wrapper">
                                        <div className="editor-preview-box">
                                            <div className="preview-wrapper">
                                                <video
                                                    className="preview-def-wh"
                                                    width="100%"
                                                    height="100%"
                                                    style={{ opacity: 0, marginBottom: "-6px" }}
                                                >
                                                    <source src={clip} type="video/mp4" />
                                                </video>
                                                <div className={`aspect-ratio-wrapper ar-${dimArr[0]}-${dimArr[1]} `}>
                                                    <div className="template-wrapper">
                                                        <div className="t1-containersone">
                                                            <div className="t1-img-wrapper t1-woman" style={{ height: "100%" }}>
                                                                <ReactPlayer onReady={() => setMediaReady(true)} width="100%" height="100%" url={currentSlide.url} playing={true} muted={true} className="img" />
                                                                {/*{*/}
                                                                {/*    mediaReady === false ?*/}
                                                                {/*        <img*/}
                                                                {/*            src={loaderImg}*/}
                                                                {/*            alt="Woman"*/}
                                                                {/*            className="img-fluid"*/}
                                                                {/*        />*/}
                                                                {/*    : ''*/}
                                                                {/*}*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="controls-box" style={{ maxWidth: controlsWidth + '%' }}>*/}
                                            {/*    <div className="row m-0 w-100 justify-content-between align-items-center">*/}
                                            {/*        <div className={`cb-col cb-col-1 col-3 ${ratio === "9:16" ? 'preview-9-16-1' : ''}`}>*/}
                                            {/*            <div className="start-end-time">*/}
                                            {/*                <input*/}
                                            {/*                    type="text"*/}
                                            {/*                    className="control-time ct-start"*/}
                                            {/*                    readOnly*/}
                                            {/*                />*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*        <div className={`cb-col cb-col-2 col-2 ${ratio === "1:1" ? 'preview-1-1' : ''}  ${ratio === "9:16" ? 'preview-9-16-2' : ''}`}>*/}
                                            {/*            <div className="play-push-left-right">*/}
                                            {/*                <ul className="list-inline d-flex justify-content-between mx-auto mb-0">*/}
                                            {/*                    <li>*/}

                                            {/*                    </li>*/}
                                            {/*                    <li>*/}
                                            {/*                        {*/}
                                            {/*                            play ?*/}
                                            {/*                                <a onClick={handlePause}>*/}
                                            {/*                                    <img src={pauseImg} alt="Play Slide" className="cursor-pointer" />*/}
                                            {/*                                </a>*/}
                                            {/*                            :*/}
                                            {/*                                <a onClick={()=>handlePlay()}>*/}
                                            {/*                                    <img src={pathimg} alt="Play Slide" className="cursor-pointer" />*/}
                                            {/*                                </a>*/}
                                            {/*                        }*/}
                                            {/*                    </li>*/}
                                            {/*                    <li>*/}

                                            {/*                    </li>*/}
                                            {/*                </ul>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*        <div className={`cb-col cb-col-3 col-3 ${ratio === "9:16" ? 'preview-9-16-3' : ''}`}>*/}
                                            {/*            <div className="start-end-time">*/}

                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                                {/* End Preview */}

                            </div>
                        </div>

                    </div>
                </section>

                {/* Reel Tiles */}
                <section className="slide-sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="slide-box">
                                    <DragDropContext onDragEnd={onSetRecorder}>
                                        <Droppable droppableId="droppable" direction="horizontal">
                                            {(provided) => (
                                                <ul className="d-flex list-inline align-items-center m-0"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}>
                                                    {
                                                        mergeVideos.length > 0 ?
                                                            mergeVideos.map((video, index) => {
                                                                return (
                                                                    <Draggable key={index} draggableId={`id_${index}`}
                                                                        index={index}>
                                                                        {(provided) => (
                                                                            <li
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                key={index}
                                                                            >
                                                                                <div style={{ position: "relative" }} className={`box-ar-wrapper slider-${dimArr[0]}-${dimArr[1]}`} onClick={() => activeSlide(video)}>
                                                                                    <img src={video.preview_image} alt="" className="img-fluid" />
                                                                                </div>
                                                                                <div className="video-tiles-controls">
                                                                                    <div className="refresh-delete">
                                                                                        <a className="cursor-pointer" onClick={() => deleteSlide(index)}>
                                                                                            <i className="far fa-trash-alt"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })
                                                            : <li style={{ padding: "10px" }}>No videos added</li>
                                                    }
                                                    {provided.placeholder}
                                                </ul>
                                            )
                                            }
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Reel Tiles End */}

            </main>
        </>
    )
}

export default ReelMarge;