import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";
import { updateCampaignName } from "../../actions/videoAction";
import { useHistory } from "react-router-dom";

const EditorHead = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(state => state.auth);
    const videoData = useSelector(state => state.videos.data);
    const videoText = useSelector(state => state.videos.text);
    const [loaderSave, setLoaderSave] = useState(false);
    const [loadRender, setLoadRender] = useState(false);

    const saveProject = (type, showMessage = true) => {
        videoData.type = type;
        if (type === 'save') {
            setLoaderSave(true);
        } else {
            setLoadRender(true);
        }

        axios({
            method: "POST",
            url: `${baseURL}save-campaign`,
            data: videoData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                if (showMessage)
                    dispatch(setAlert(res.data.message, 'success'));
                if (type === 'render') {
                    history.push('/projects');
                }
            } else {
                if (showMessage)
                    dispatch(setAlert(res.data.message, 'danger'));
            }

            setLoaderSave(false);
            setLoadRender(false);
        }).catch(err => {
            setLoaderSave(false);
            setLoadRender(false);
        })
    }

    const onChangeName = (e) => {
        dispatch(updateCampaignName(e.target.value));
    }

    useEffect(() => {
        if (videoText !== false) {
            saveProject('save', false);
        }
    }, [])

    return (
        <>
            <Alert />
            <div className="save-render-sec">
                <div className="row m-0">
                    <div className="col-md-12 d-flex flex-wrap align-items-center justify-content-center gap-3">
                        <div className="add-campaign">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Add Campaign Name"
                                defaultValue={videoData?.name}
                                onChange={(e) => onChangeName(e)}
                            />
                        </div>
                        <div className="save-projectbtn">
                            <button type="button" className="btn-change6" onClick={() => saveProject('save')}>
                                <span className="mr-1">
                                    {loaderSave ? <i className="fa fa-spinner fa-spin" /> : <i className="far fa-save" />}
                                </span>
                                Save Project
                            </button>
                        </div>
                        <div className="render-projectbtn">
                            <button type="button" className="btn-change6" onClick={() => saveProject('render')}>
                                <span className="mr-1">
                                    {loadRender ? <i className="fa fa-spinner fa-spin" /> : <i className="fas fa-file-video" />}
                                </span>
                                Render
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(EditorHead)